<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <EavMenu :slug="slug" />
                <div class="d-flex align-center mb-4">
                    <h1>
                        {{ $t('eav_entity.plural') }}
                    </h1>
                    <v-spacer></v-spacer>
                    <Btn color="secondary" :to="{name: 'eav.entity.edit', params: {uuid: 'create'}}">{{ $t('create') }}</Btn>
                </div>
                <ResizableTable
                    :rows="elements"
                    :columns="[
                        {
                            active: true,
                            label: $t('id'),
                            prop: 'id',
                            align: 'start',
                            sortable: false
                        },
                        // {
                        //     active: true,
                        //     label: $t('entity_id'),
                        //     prop: 'entity_id',
                        //     align: 'start',
                        //     sortable: false
                        // },
                        // {
                        //     active: true,
                        //     label: $t('entity_type'),
                        //     prop: 'entity_type',
                        //     align: 'start',
                        //     sortable: false
                        // },
                        {
                            active: true,
                            label: $t('properties'),
                            prop: 'properties',
                            align: 'start',
                            sortable: false
                        },
                        {
                            active: true,
                            label: $t('created_at'),
                            prop: 'created_at',
                            align: 'start',
                            sortable: false
                        },
                        {
                            active: true,
                            label: $t('updated_at'),
                            prop: 'updated_at',
                            align: 'start',
                            sortable: false
                        }
                    ]"
                >
                    <template v-slot:id="{ item }">
                        {{ item.id }}
                        <Btn icon text small :to="{name: 'eav.entity.edit', params: { uuid: item.uuid } }">
                            <v-icon small>mdi-pencil-outline</v-icon>
                        </Btn>
                        <Btn icon text small @click="onDeleteEntityBtnClick(item.uuid)">
                            <v-icon small>mdi-trash-can-outline</v-icon>
                        </Btn>
                    </template>
                    <template v-slot:properties="{ item }">
                        <table class="styled-table" width="100%" style="text-align: left;" border="1">
                            <tr>
                                <th>
                                    {{ $t('name') }}
                                </th>
                                <th>
                                    {{ $t('type') }}
                                </th>
                                <th>
                                    {{ $t('value') }}
                                </th>
                            </tr>
                            <tr v-for="(property) in item.properties" :key="property.id">
                                <td>{{ property.name }}</td>
                                <td>{{ property.type }}</td>
                                <td>{{ property.value === null ? 'null' : property.value }}</td>
                            </tr>
                        </table>
                    </template>
                </ResizableTable>
                <v-pagination :key="page" v-model="page" :total-visible="7"  flat class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
            </div>
        </div>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import SettingMenu from "../components/SettingMenu";
import TextInput from "../components/Form/TextInput";
import SelectInput from "../components/Form/SelectInput";
import Btn from "@/components/Form/Btn.vue";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import EavMenu from "@/components/Eav/EavMenu.vue";

export default {
    name: "Pages",

    props: {
        slug: {
            type: String,
            required: true
        }
    },

    directives: {
        mask,
    },
    components: {
        EavMenu,
        Btn,
        SettingMenu
    },

    watch: {
        $route(to, from) {
            this.page = parseInt(to.query.page) || 1;
            this.fetchItem();
        }
    },

    data() {
        return {
            elements: [],
            page: 1,
            loading: false,
            pageCount: 1
        };
    },
    computed: {
        ...mapGetters(['lang', 'listLanguageNames']),
    },
    mounted() {
        this.fetchItem();
    },
    methods: {
        fetchItem() {
            this.loading = true
            this.$http.get(`admin/eav/${this.slug}/property`, {
                params: {
                    page: this.page
                }
            })
                .then(response => {
                    this.elements = response.body.data;
                    this.pageCount = response.body.meta.last_page;
                })
                .catch(errorResp => {
                    this.errors = errorResp.body.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        onDeleteEntityBtnClick(uuid) {
            this.$http.delete(`admin/eav/${this.slug}/${uuid}/property`).then(() => {
                this.fetchItem();
            })
        },

        onPageChange() {
            this.$router.push({query: {page: this.page}});
        },
    }
}
</script>
