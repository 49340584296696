var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"pl-4 pr-0 pt-2 d-flex"},[_c('div',{staticClass:"pr-0 pt-4 left_filter"},[_c('setting-menu')],1),_c('div',{staticClass:"div_body pl-3 pr-0 mx-0"},[_c('EavMenu',{attrs:{"slug":_vm.slug}}),_c('div',{staticClass:"d-flex align-center mb-4"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('eav_entity.plural'))+" ")]),_c('v-spacer'),_c('Btn',{attrs:{"color":"secondary","to":{name: 'eav.entity.edit', params: {uuid: 'create'}}}},[_vm._v(_vm._s(_vm.$t('create')))])],1),_c('ResizableTable',{attrs:{"rows":_vm.elements,"columns":[
                    {
                        active: true,
                        label: _vm.$t('id'),
                        prop: 'id',
                        align: 'start',
                        sortable: false
                    },
                    // {
                    //     active: true,
                    //     label: $t('entity_id'),
                    //     prop: 'entity_id',
                    //     align: 'start',
                    //     sortable: false
                    // },
                    // {
                    //     active: true,
                    //     label: $t('entity_type'),
                    //     prop: 'entity_type',
                    //     align: 'start',
                    //     sortable: false
                    // },
                    {
                        active: true,
                        label: _vm.$t('properties'),
                        prop: 'properties',
                        align: 'start',
                        sortable: false
                    },
                    {
                        active: true,
                        label: _vm.$t('created_at'),
                        prop: 'created_at',
                        align: 'start',
                        sortable: false
                    },
                    {
                        active: true,
                        label: _vm.$t('updated_at'),
                        prop: 'updated_at',
                        align: 'start',
                        sortable: false
                    }
                ]},scopedSlots:_vm._u([{key:"id",fn:function({ item }){return [_vm._v(" "+_vm._s(item.id)+" "),_c('Btn',{attrs:{"icon":"","text":"","small":"","to":{name: 'eav.entity.edit', params: { uuid: item.uuid } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-outline")])],1),_c('Btn',{attrs:{"icon":"","text":"","small":""},on:{"click":function($event){return _vm.onDeleteEntityBtnClick(item.uuid)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}},{key:"properties",fn:function({ item }){return [_c('table',{staticClass:"styled-table",staticStyle:{"text-align":"left"},attrs:{"width":"100%","border":"1"}},[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('name'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('type'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('value'))+" ")])]),_vm._l((item.properties),function(property){return _c('tr',{key:property.id},[_c('td',[_vm._v(_vm._s(property.name))]),_c('td',[_vm._v(_vm._s(property.type))]),_c('td',[_vm._v(_vm._s(property.value === null ? 'null' : property.value))])])})],2)]}}])}),_c('v-pagination',{key:_vm.page,staticClass:"main-pagination__pagination pagination_table",attrs:{"total-visible":7,"flat":"","length":_vm.pageCount,"disabled":_vm.loading},on:{"input":_vm.onPageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }